<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="4" v-for="it in imgList" :key="it.id"
        ><div class="grid-content">
          <img class="img" :src="it.face_photo" alt="" />
          <div>
            {{ it.dept_name.replace("保定市支队", "") }}<br />{{ it.pname
            }}{{ it.police_num }}
          </div>
        </div></el-col
      >
    </el-row>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="recoderCount"
      :page-size="pageSize"
      @current-change="page_change"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      pageSize: 10,
      recoderCount: 0,
      imgList: [],
      page: 1,
    };
  },
  computed: {},
  created() {
    // let ay = [];
    // for (let i = 1; i < 31; i++) {
    //   ay.push({
    //     id: i,
    //     img: "/imgs/1.jpg",
    //   });
    // }
    // this.imgList = ay;
    // this.recoderCount = ay.length * 4 + 1;

    this.getList();
  },
  watch: {},
  methods: {
    getList() {
      this.$api
        .xrfjdc_list({
          start_date: "2022-03-13 00:00:00",
          end_date: "2022-03-13 23:00:00",
          key_name: "大队",
        })
        .then(
          (res) => {
            console.log(res.data.data);
            this.imgList = res.data.data;
          },
          (err) => {
            console.log(err);
          }
        );
      // this.$api.news_list({ page: this.page, pageSize: this.pageSize }).then(
      //   (res) => {
      //     this.recoderCount = res.data.total;
      //     this.imgList = res.data.rows;
      //   },
      //   (err) => {
      //     console.log(err);
      //   }
      // );
    },
    page_change(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>

<style scoped>
.grid-content {
  position: relative;
}
.img {
  width: 200px;
  height: 270px;
}
.search {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .searche_item {
    padding-right: 10px;
  }
}
</style>
